<template>
  <div class="pa-2">
    <v-card class="mx-auto" max-width="800">
      <v-card-title>更新情報</v-card-title>
      <v-divider></v-divider>
      <v-container fluid id="updates" class="mt-2">
        <v-row
          v-for="update in updates"
          :ref="`update-${update.id}`"
          :key="update.id"
          align="center"
          justify="center"
        >
          <v-col>
            <v-chip
              class="ma-2"
              :color="update.type === 'update' ? 'primary' : 'accent'"
              :text-color="update.type === 'update' ? 'white' : 'black'"
            >
              {{ update.type }}
            </v-chip>
            <span>{{ update.date }}</span>
            <div class="px-2 font-weight-bold">
              {{ update.title }}
            </div>
            <div class="ma-4"><VueMarkdown :source="update.markdown" /></div>
            <v-divider />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render";
const updates = require("@/assets/updates.json");

export default {
  components: { VueMarkdown },
  data() {
    return {
      updates,
      hash: this.$route.hash,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace("#", "");
        this.scrollToAnchorPoint(refName);
      }
    });
  },
  methods: {
    scrollToAnchorPoint(refName) {
      const el = this.$refs[refName] && this.$refs[refName][0];
      el && el.scrollIntoView({ behavior: "smooth" });
    },
  },
  metaInfo: {
    title: "更新情報",
    meta: [
      {
        vmid: "update",
        name: "update",
        content: "アプリの更新情報を掲載しています。",
      },
    ],
  },
};
</script>